<template>
  <div class="main-body">
    <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item label="关键词过滤" extra="多个关键字之间用英文“,”隔开">
        <a-textarea v-model:value="formState.forbidden_words" placeholder="请输入关键词" />
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 7, offset: 3 }">
        <a-button type="primary" @click="onSubmit">保存</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { defineComponent, reactive, onMounted } from "vue";
import * as Api from "@/views/setting/api";
import { message } from "ant-design-vue";

export default defineComponent({
  components: {
  },
  setup() {
    const formState = reactive({
      forbidden_words:'',
    });

    const onSubmit = () => {
      Api.save_setting(formState).then((res) => {
        message.success(res.message);
      });
    };

    const getInitSetting = () => {
      Api.get_global_setting().then((res) => {
          formState.forbidden_words = res.data.forbidden_words ? res.data.forbidden_words : "";
      });
    };

    onMounted(() => {
      getInitSetting();
    });

    return {
      labelCol: {
        span: 3,
      },
      wrapperCol: {
        span: 7,
      },
      formState,
      onSubmit,
    };
  },
});
</script>